<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <div class="row">
            <div class="col-6">
                <h2 class="font-weight-normal">Templates</h2>
            </div>
            <div class="col-6 text-right mt-20">
                <div class="dropzone">
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="addTemplatesWorkingCopyPopup = true">
                        <i class="fas fa-plus"></i>
                        <span>Add new template</span>
                    </mercur-button>
                </div>
            </div>
        </div>

        <mercur-dialog :is-open.sync="addTemplatesWorkingCopyPopup" width="30%">
            <template-add @save="saveTemplatesWorkingCopy" @cancel="addTemplatesWorkingCopyPopup = false" :loading="loading"></template-add>
        </mercur-dialog>

        <ag-grid-vue
            class="ag-grid fill ag-theme-material border"
            :columnDefs="columnDefs"
            :animateRows="true"
            rowModelType="serverSide"
            :defaultColDef="defaultColDef"
            :pagination="true"
            :enableRangeSelection="false"
            :suppressCellSelection="true"
            @grid-ready="onGridReady"
        ></ag-grid-vue>
    </mercur-card>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'
import TemplateAdd from '@/components/templates/TemplateAdd'
import CONFIG from '@root/config'

export default {
    name: 'TemplateOverview',
    components: { TemplateAdd, AgGridVue },
    data () {
        return {
            gridApi: null,
            columnDefs: null,
            addTemplatesWorkingCopyPopup: false,
            loading: false,
            defaultColDef: null,
        }
    },
    beforeMount () {
        this.defaultColDef = {
            resizable: true,
        }
        this.columnDefs = [
            {
                headerName: 'Name',
                field: 'templateName',
                sortable: true,
            },
            {
                headerName: 'Filename',
                field: 'originalFileName',
                sortable: true,
            },
            {
                headerName: 'Created',
                field: 'dateCreated',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: 'Updated',
                field: 'dateUpdated',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
        ]
    },
    watch: {
        '$route.params.templateSection' () {
            this.setupGridDataSource()
        },
    },
    methods: {
        onGridReady (params) {
            this.gridApi = params.api
            this.setupGridDataSource()
        },
        setupGridDataSource () {
            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES.OVERVIEW.replace('{supplierId}', this.supplierId).replace('{templateSection}', this.$route.params.templateSection),
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    this.gridApi.hideOverlay()
                    if (data.items.length === 0) {
                        this.gridApi.showNoRowsOverlay()
                    }
                    successCallback(data.items, data.items[0].totalRows)
                },
            })
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            this.gridApi.setServerSideDatasource(this.serverSideDatasource)
        },
        saveTemplatesWorkingCopy (files) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES.ADD.replace('{supplierId}', this.supplierId).replace('{templateSection}', this.$route.params.templateSection)
            this.addJob(url)
            this.loading = true

            return this.$api.post(url, files).then(({ data }) => {
                this.addTemplatesWorkingCopyPopup = false
                this.gridApi.purgeServerSideCache()
                this.$root.$emit('notification:global', {
                    message: `Templates saved`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving templates failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },
}
</script>
