<template>
    <div>
        <div class="template-add">
            <h2 class="font-weight-normal">Add templates</h2>
            <filedropper
                class="filedropper-area"
                @uploadUpdate="uploadUpdate"
                :url="uploadUrl"
            ></filedropper>
            <div class="filelist">
                <div class="file" v-for="(file, key) in files" :key="key">
                    <div class="flex align-items-center">
                        <div class="file__progress" :class="getFileProgressClasses(file)" :style="getFileProgressStyling(file)"></div>
                        <mercur-button class="btn btn-icon btn-icon-square mt-2" @click="files.splice(key, 1)"><strong>&times;</strong></mercur-button>
                        <div class="w-100">
                            <mercur-input v-model="file.templateName">
                                Template name
                            </mercur-input>
                        </div>
                    </div>
                    <div class="w-100">
                        <hr>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <mercur-button class="btn btn-raised text-uppercase" @click="$emit('cancel')">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" :disabled="loading || files.length === 0" @click="$emit('save', files)">Save</mercur-button>
            </div>
        </div>
        <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
    </div>
</template>

<script>
import Filedropper from '@/components/utils/Filedropper'
import CONFIG from '@root/config'
export default {
    name: 'TemplateAdd',
    props: ['loading'],
    data () {
        return {
            files: [],
        }
    },
    components: { Filedropper },
    computed: {
        uploadUrl () {
            return CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES.UPLOAD_BULK
                .replace('{supplierId}', this.supplierId)
                .replace('{templateSection}', this.$route.params.templateSection)
        },
    },
    methods: {
        uploadUpdate ($event) {
            const file = $event.file
            if ($event.type === 'start') {
                this.files.unshift({
                    ...file,
                    status: 'pending',
                    templateName: file.originalFilename,
                    percentCompleted: 0,
                })

                return
            }

            const listFile = this.files.find(workingFile => file.objectName === workingFile.objectName)

            if ($event.type === 'completed') {
                this.$set(listFile, 'status', 'completed')
                setTimeout(() => {
                    this.$set(listFile, 'status', null)
                }, 3000)
                return
            }

            if ($event.type === 'progress') {
                this.$set(listFile, 'percentCompleted', $event.percentCompleted)
            }
        },
        getFileProgressClasses (file) {
            let classes = []
            if (file.status === 'pending' || file.status === 'completed') {
                classes.push(`is-${file.status}`)
            }

            return classes.map(modifier => `file__progress--${modifier}`)
        },
        getFileProgressStyling (file) {
            return {
                transform: `translate(-${100 - file.percentCompleted}%, 0)`,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.filedropper-area {
    margin-top: 10px;
}
.template-add {
    padding: 10px 30px;
}
.filelist {
    overflow: hidden;
    margin-top: 10px;
}
.file {
    position: relative;
    overflow: hidden;

    &__progress {
        z-index: -1;
        width: 100%;
        transition: 400ms all;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-100%, 0);

        &--is-pending {
            background-color: #fff2c3;
        }

        &--is-completed {
            background-color: #e8ffe5;
            transition: background-color 3s;
        }
    }
}
</style>
